import React, { Fragment } from 'react'
import { Component } from 'reflux'
import { ProductStore, ProductActions, STATUS } from 'Stores/productStore'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import { LoginStore } from 'Stores/loginStore'
import { Button, Form, Input, Row, Col, Table, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Label } from 'reactstrap'
import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import { toast } from 'react-toastify'
import ProductImageUpload from 'Modules/products/ProductImageUpload.js'
import VideoUpload from '../../util/VideoUpload'
const productStatus = ['AVAILABLE', 'PREORDER', 'BACKORDER', 'UNAVAILABLE']
const MAX_SIZE = 25
const stickyDurations = [1, 2, 4, 8, 16, 24, 36, 48]
const StyledCheckBox = styled(Input)`
  margin: 0.75rem !important;
`
const StyledImg = styled.img`
  width: 364px;
  height: 492px;
  object-fit: cover;
`
const StyledAttachmentDiv = styled.div`
  max-width: 15vw;
  margin-top: 5px;
`
const StyledAttachmentLabel = styled.span`
  line-height: 30px;
`
const StyledAttachmentInput = styled(Input)`
  width: auto;
  max-width: 50px;
  margin-left: 5px;
`
const StyledAttachmentCheckbox = styled(Input)`
  margin: 10px;
  position: relative;
`
const StyledAttachmentRow = styled(Row)`
  padding-left: 15px !important;
`
const StyledAttachmentOrderCol = styled(Col)`
  display: contents;
`
const StyledAttachmentImageCol = styled(Col)`
  display: inherit;
  padding-left: 0px !important;
`
export default class ProductForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [ProductStore, LoginStore]
    this.storeKeys = [
      'product',
      'status',
      'catalogs',
      'brands',
      'categoryProducts',
      'categorySuppliers',
      'categoryTags',
      'suppliers',
      'colors',
      'subCategories',
      'languages',
      'settings'
    ]
  }
  componentDidMount() {
    ProductActions.getCatalogs()
    ProductActions.getCategories()
    ProductActions.getSubCategories()
    ProductActions.getSuppliers()
    ProductActions.getColors()
    if (this.state.settings && this.state.settings.BrandID) {
      ProductActions.getLanguages(this.state.settings.BrandID)
    }
  }
  componentDidUpdate() {
    if (this.state.status === STATUS.PRODUCT_TITLE_NONE) {
      toast.error('Product Title cannot be empty', {
        autoClose: 5000
      })
      ProductActions.clearFormToast()
    }
    if (this.state.status === STATUS.PRODUCT_CONTENT_NONE) {
      toast.error('Product Content cannot be empty', {
        autoClose: 5000
      })
      ProductActions.clearFormToast()
    }
    if (this.state.status === STATUS.VARIATION_CONTENT_NONE) {
      toast.error('Variation UID cannot be empty', {
        autoClose: 5000
      })
      ProductActions.clearFormToast()
    }
    if (this.state.status === STATUS.VARIATION_EMAIL_NONE) {
      toast.error('Indsæt email for hver variation med minimum lager beholdnings', {
        autoClose: 5000
      })
      ProductActions.clearFormToast()
    }
    if (this.state.status === STATUS.INVALID_EMAIL) {
      toast.error('Please Enter valid Email in variation Kontakt email', {
        autoClose: 5000
      })
      ProductActions.clearFormToast()
    }
    if (this.state.status === STATUS.PRODUCT_ERROR) {
      toast.error('Error in product feed', {
        autoClose: 5000
      })
      ProductActions.clearFormToast()
    }
    if (this.state.status === STATUS.CATALOGS_ERROR) {
      toast.error('No catalog were assigned', {
        autoClose: 5000
      })
      ProductActions.clearFormToast()
    }
  }
  resetProduct = () => {
    if (_.get(this.props, 'match.params.ProductID')) {
      ProductActions.getProductDetails(this.props.match.params.ProductID)
    } else {
      ProductActions.createEmptyProduct()
    }
  }
  addCatalog = () => {
    ProductActions.addField(
      {
        CatalogID: '',
        productPrice: '',
        productPriceAdjust: '',
        sticky: false
      },
      'catalogs'
    )
  }
  addVariation = () => {
    ProductActions.addField(
      {
        variationTitle: '',
        variationUID: '',
        variationStock: null,
        variationStockable: false,
        externalStock: false,
        attachments: [{ ImageID: '' }],
        subscriptions: [
          {
            triggerValue: '',
            subscriberContactValue: ''
          }
        ],
        poolOrder: !!this.state.product.variations.length && this.state.product.variations.length
      },
      'variations'
    )
  }
  addCategory = type => {
    ProductActions.addField({ CategoryID: '' }, type)
  }
  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to='/products' />
    }
    if (this.state.status === STATUS.FETCHING_PRODUCT) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen header={<strong>{_.get(this.props, 'match.params.ProductID') ? 'Detaljer' : 'Opret'}</strong>}>
          <Form>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Name</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='text'
                  value={this.state.product.elementTitle}
                  name='elementTitle'
                  id='elementTitle'
                  onChange={ProductActions.updateProductField}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Content</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='textarea'
                  value={this.state.product.elementContent}
                  name='elementContent'
                  id='elementContent'
                  onChange={ProductActions.updateProductField}
                />
              </Col>
            </Row>
            <FormGroup row>
              <Label for='textColor' sm={2}>
                Product Translations
              </Label>
              <Col sm={10}>
                {!!this.state.product &&
                  !!this.state.product.translations &&
                  !!this.state.product.translations.length &&
                  this.state.product.translations.map((translation, inx) => {
                    return (
                      !translation.removed && (
                        <div key={inx}>
                          <Row>
                            <Col sm={11}>
                              <Input
                                type='select'
                                name='LanguageID'
                                value={translation.LanguageID ? Number(translation.LanguageID) : ''}
                                id='LanguageID'
                                onChange={e => ProductActions.updateData(e, inx, 'translations')}
                              >
                                <option value='' disabled>
                                  Click to Select
                                </option>
                                {!!this.state.languages &&
                                  !!this.state.languages.length &&
                                  this.state.languages.map((e, i) => {
                                    return (
                                      <option value={Number(e.LanguageID)} key={i}>
                                        {e.languageName}
                                      </option>
                                    )
                                  })}
                              </Input>
                            </Col>
                            <Col sm={1}>
                              <Button onClick={() => ProductActions.removeTranslation(inx)} color={'danger'}>
                                <FontAwesomeIcon icon={'minus'} />
                              </Button>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Label for='textColor' sm={2}>
                              Title
                            </Label>
                            <Col sm={9}>
                              <Input
                                name='elementTitle'
                                value={translation.elementTitle}
                                type='text'
                                onChange={e => ProductActions.updateData(e, inx, 'translations')}
                              />
                            </Col>
                            <Col sm={1}>
                              <Button onClick={ProductActions.addTranslation}>
                                <FontAwesomeIcon icon='plus' />
                              </Button>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Label for='textColor' sm={2}>
                              Content
                            </Label>
                            <Col sm={9}>
                              <Input
                                name='elementContent'
                                value={translation.elementContent}
                                type='textarea'
                                onChange={e => ProductActions.updateData(e, inx, 'translations')}
                              />
                            </Col>
                          </Row>
                          <br></br>
                        </div>
                      )
                    )
                  })}
              </Col>
            </FormGroup>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Product Price</label>
              </Col>
              <Col xs='3'>
                <InputGroup>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input value={this.state.product.productPrice} name='productPrice' id='productPrice' onChange={ProductActions.updateProductField} />
                </InputGroup>
              </Col>
              <Col xs='1s'>
                <label>Profit percentage</label>
              </Col>
              <Col xs='3'>
                <InputGroup>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={this.state.product.priceWithMarkup || ''}
                    name='priceWithMarkup'
                    id='priceWithMarkup'
                    disabled
                    style={{ color: this.state.product.priceWithMarkup < 30 ? 'red' : 'inherit' }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Markup</label>
              </Col>
              <Col xs='10'>
                <InputGroup>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>%</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    value={this.state.product.productPriceAdjust}
                    name='productPriceAdjust'
                    id='productPriceAdjust'
                    onChange={ProductActions.updateProductField}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Retail Price</label>
              </Col>
              <Col xs='10'>
                <InputGroup>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input value={this.state.product.retailPrice} name='retailPrice' id='retailPrice' onChange={ProductActions.updateProductField} />
                </InputGroup>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Weight</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='select'
                  name='productWeightInGram'
                  value={this.state.product.productWeightInGram}
                  id='productWeightInGram'
                  onChange={ProductActions.updateProductField}
                >
                  <option value='' disabled>
                    Click to select
                  </option>
                  <option value={null}>Blank</option>
                  <option value={10}>10g</option>
                  <option value={50}>50g</option>
                  <option value={100}>100g</option>
                  <option value={150}>150g</option>
                  <option value={250}>250g</option>
                  <option value={500}>500g</option>
                  <option value={750}>750g</option>
                  {_.range(1, MAX_SIZE + 1).map(value => (
                    <option key={value} value={value * 1000}>
                      {value}Kg
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Moms undtaget</label>
              </Col>
              <Col xs='10'>
                <StyledCheckBox
                  type='checkbox'
                  value={this.state.product.taxExempted}
                  className='m-1 batch-header'
                  checked={this.state.product.taxExempted}
                  name='taxExempted'
                  onChange={ProductActions.updateProductField}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Dropship Product</label>
              </Col>
              <Col xs='10'>
                <StyledCheckBox
                  type='checkbox'
                  value={this.state.product.enableDropship}
                  className='m-1 batch-header'
                  checked={this.state.product.enableDropship}
                  name='enableDropship'
                  onChange={ProductActions.updateProductField}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Dropship Price</label>
              </Col>
              <Col xs='10'>
                <Input
                  disabled={!this.state.product.enableDropship}
                  value={this.state.product.dropshipFreightPrice}
                  id='dropshipFreightPrice'
                  name='dropshipFreightPrice'
                  onChange={ProductActions.updateProductField}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Brand</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='select'
                  name='manufacturer'
                  value={this.state.product.manufacturer}
                  id='manufacturer'
                  onChange={ProductActions.updateProductField}
                >
                  <option value='' disabled>
                    Click to select
                  </option>
                  {this.state.brands.length &&
                    this.state.brands.map((e, i) => {
                      return (
                        <option value={e.categoryName} key={i}>
                          {e.categoryName}
                        </option>
                      )
                    })}
                </Input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Minimum Order Quantity</label>
              </Col>
              <Col xs='2'>
                <Input
                  value={this.state.product.minCount}
                  id='minCount'
                  name='minCount'
                  type='number'
                  min={1}
                  onChange={ProductActions.updateProductField}
                />
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Supplier</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='select'
                  name='SupplierID'
                  value={this.state.product.SupplierID}
                  id='supplier'
                  onChange={ProductActions.updateProductField}
                >
                  <option value=''>Click to select</option>
                  {this.state.suppliers.length &&
                    this.state.suppliers.map((e, i) => {
                      return (
                        <option value={e.SupplierID} key={i}>
                          {e.supplierName}
                        </option>
                      )
                    })}
                </Input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>No of colli</label>
              </Col>
              <Col xs='2'>
                <Input
                  type='number'
                  name='colli'
                  value={this.state.product.colli || 0}
                  id='supplier'
                  onChange={ProductActions.updateProductField}
                ></Input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Currency</label>
              </Col>
              <Col xs='2'>
                <Input
                  type='text'
                  name='currency'
                  value={this.state.product.currency || ''}
                  id='supplier'
                  onChange={ProductActions.updateProductField}
                ></Input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Currency price</label>
              </Col>
              <Col xs='2'>
                <Input
                  type='text'
                  name='currencyPrice'
                  value={this.state.product.currencyPrice || ''}
                  id='supplier'
                  onChange={ProductActions.updateProductField}
                ></Input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Categories</label>
              </Col>
              <Col xs='10'>
                <Table className='mb-sm-0'>
                  <thead>
                    <tr>
                      <th className='w-100'>Product</th>
                      <th>Primary</th>
                      <th>
                        <Button onClick={() => this.addCategory('products')}>
                          <FontAwesomeIcon icon='plus' />
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.product.products &&
                      !!this.state.product.products.length &&
                      this.state.product.products.map((item, key) => {
                        return (
                          <Fragment key={key}>
                            <tr>
                              <td className='w-100'>
                                <Input
                                  type='select'
                                  name='CategoryID'
                                  value={item.CategoryID}
                                  id='productCategoryID'
                                  onChange={t => ProductActions.updateData(t, key, 'products')}
                                >
                                  <option value='' disabled>
                                    Click to Select
                                  </option>
                                  {!!this.state.categoryProducts.length &&
                                    this.state.categoryProducts.map((e, i) => {
                                      return (
                                        <option value={e.CategoryID} key={i}>
                                          {e.categoryName}
                                        </option>
                                      )
                                    })}
                                </Input>
                              </td>
                              <td>
                                <Input
                                  style={{ marginLeft: 10 }}
                                  type='radio'
                                  checked={item.isPrimary === 1 ? true : false}
                                  name='isPrimary'
                                  onChange={() => {
                                    this.state.product.products.map((cat, index) => {
                                      if (item.CategoryID === cat.CategoryID) {
                                        ProductActions.updateData({ target: { value: 1, name: 'isPrimary' } }, key, 'products')
                                      } else {
                                        ProductActions.updateData({ target: { value: 0, name: 'isPrimary' } }, index, 'products')
                                      }
                                    })
                                  }}
                                />
                              </td>
                              <td>
                                <Button
                                  onClick={() => {
                                    ProductActions.removeData(key, 'products')
                                  }}
                                >
                                  <FontAwesomeIcon icon='minus' />
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              {!!this.state.product &&
                                !!this.state.product.products &&
                                !!this.state.product.products.length &&
                                this.state.product.products[key] !== '' && (
                                  <Row style={{ margin: '10px 0' }}>
                                    <Col xs={3} style={{ padding: 0 }}>
                                      Sub-Categories
                                    </Col>
                                    <Col xs={9}>
                                      <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                                        {this.state.subCategories
                                          .filter(sc => {
                                            const selectedCategory = this.state.product.products[key]
                                              ? Number(this.state.product.products[key].CategoryID)
                                              : []
                                            return sc.linkedCategoryIDs.includes(selectedCategory)
                                          })
                                          .map(sc => (
                                            <Fragment key={`${sc.SubCategoryID}`}>
                                              <Button
                                                onClick={() => ProductActions.toggleLinkedSubCategory(sc, key)}
                                                color={
                                                  !!this.state.product.linkedSubCategoryIDs &&
                                                  !!this.state.product.linkedSubCategoryIDs.length &&
                                                  this.state.product.linkedSubCategoryIDs.includes(sc.SubCategoryID)
                                                    ? 'secondary'
                                                    : 'light'
                                                }
                                              >
                                                {sc.subCategoryName}
                                              </Button>
                                            </Fragment>
                                          ))}
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                            </tr>
                          </Fragment>
                        )
                      })}
                  </tbody>
                </Table>

                <Table className='mb-sm-0'>
                  <thead>
                    <tr>
                      <th className='w-100'>Tags</th>
                      <th>
                        <Button onClick={() => this.addCategory('tags')}>
                          <FontAwesomeIcon icon='plus' />
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!this.state.product.tags &&
                      !!this.state.product.tags.length &&
                      this.state.product.tags.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <Input
                                type='select'
                                name='CategoryID'
                                value={item.CategoryID || ''}
                                id='CategoryID'
                                onChange={t => ProductActions.updateData(t, key, 'tags')}
                              >
                                <option value='' disabled>
                                  Click to Select
                                </option>
                                {!!this.state.categoryTags &&
                                  !!this.state.categoryTags.length &&
                                  this.state.categoryTags.map((e, i) => {
                                    return (
                                      <option value={e.CategoryID} key={i}>
                                        {e.categoryName}
                                      </option>
                                    )
                                  })}
                              </Input>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  ProductActions.removeData(key, 'tags')
                                }}
                              >
                                <FontAwesomeIcon icon='minus' />
                              </Button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Catalogs</label>
              </Col>
              <Col xs='10'>
                <Table className='mb-sm-0'>
                  <thead>
                    <tr>
                      <th>Catalog</th>
                      <th>Override Price</th>
                      <th>Override Markup</th>
                      <th>Override Basisprice</th>
                      <th>Tilbud</th>
                      <th>Tilbud Price</th>
                      <th>Sticky</th>
                      <th>Sticky Duration</th>
                      <th>Sticky Endtime</th>
                      <th>Set Product as Not New</th>
                      <th>Set Product as New</th>
                      <th>Product New Till</th>
                      <th className='float-right'>
                        <Button onClick={this.addCatalog}>
                          <FontAwesomeIcon icon='plus' />
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.product.catalogs &&
                      !!this.state.product.catalogs.length &&
                      this.state.product.catalogs.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <Input
                                type='select'
                                name='CatalogID'
                                value={item.CatalogID}
                                id='CatalogID'
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              >
                                <option value='' disabled>
                                  Click to Select
                                </option>
                                {this.state.catalogs.map((e, i) => {
                                  return (
                                    <option value={e.CatalogID} key={i}>
                                      {e.moduleName}
                                    </option>
                                  )
                                })}
                              </Input>
                            </td>
                            <td>
                              <Input
                                type='text'
                                name='productPrice'
                                value={item.productPrice}
                                id='productPrice'
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              />
                            </td>
                            <td>
                              <Input
                                type='text'
                                name='productPriceAdjust'
                                value={item.productPriceAdjust}
                                id='productPriceAdjust'
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              />
                            </td>
                            <td>
                              <Input
                                type='text'
                                name='basisPrice'
                                value={item.basisPrice}
                                id='basisPrice'
                                disabled={!item.enableDiscount}
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              />
                            </td>
                            <td>
                              <StyledCheckBox
                                type='checkbox'
                                value={item.enableDiscount}
                                className='m-1 batch-header'
                                checked={item.enableDiscount}
                                name='enableDiscount'
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              />
                            </td>
                            <td>
                              <Input
                                type='text'
                                name='discountPrice'
                                value={item.discountPrice}
                                id='discountPrice'
                                disabled={!item.enableDiscount}
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              />
                            </td>
                            <td>
                              <StyledCheckBox
                                type='checkbox'
                                value={item.sticky}
                                className='m-1 batch-header'
                                checked={item.sticky}
                                name='sticky'
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              />
                            </td>
                            <td>
                              <Input
                                type='select'
                                name='stickyDuration'
                                value={item.stickyDuration}
                                id='stickyDuration'
                                disabled={!item.sticky}
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              >
                                <option value='' disabled>
                                  Click to Select
                                </option>
                                {stickyDurations.map((e, i) => {
                                  return (
                                    <option value={e} key={i}>
                                      {e}
                                    </option>
                                  )
                                })}
                              </Input>
                            </td>
                            <td>
                              <p>{item.stickyEndtime ? moment(item.stickyEndtime).format('DD-MM-YYYY HH:mm') : ''}</p>
                            </td>
                            <td>
                              <StyledCheckBox
                                type='checkbox'
                                value={item.isNotNew}
                                className='m-1 batch-header'
                                checked={item.isNotNew}
                                name='isNotNew'
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              />
                            </td>
                            <td>
                              <StyledCheckBox
                                type='checkbox'
                                value={item.setProductNew}
                                className='m-1 batch-header'
                                checked={item.setProductNew}
                                name='setProductNew'
                                onChange={t => ProductActions.updateData(t, key, 'catalogs')}
                              />
                            </td>
                            <td>
                              <p>
                                {item.isNotNew
                                  ? 'NA'
                                  : moment(item.timeInsert)
                                      .add(10, 'days')
                                      .format('DD-MM-YYYY HH:mm')}
                              </p>
                            </td>
                            <td>
                              <Button
                                className='float-right'
                                onClick={() => {
                                  ProductActions.removeData(key, 'catalogs')
                                }}
                              >
                                <FontAwesomeIcon icon='minus' />
                              </Button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Variations</label>
              </Col>
              <Col xs='10'>
                <Table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>UID</th>
                      <th>Reset Stock</th>
                      <th>External Stock</th>
                      <th>Limited</th>
                      <th>Override UI</th>
                      <th>Minimum lagerbeholdning</th>
                      <th>Kontakt email</th>
                      <th>Size</th>
                      <th>Color</th>
                      <th>Preorder</th>
                      <th>PoolOrder</th>
                      <th>Preorder Stock</th>
                      <th>Images</th>
                      <th className='float-right'>
                        <Button onClick={this.addVariation}>
                          <FontAwesomeIcon icon='plus' />
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!this.state.product.variations.length &&
                      this.state.product.variations
                        .sort((a, b) => a.poolOrder - b.poolOrder)
                        .map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <Input
                                  type='text'
                                  name='variationTitle'
                                  value={item.variationTitle}
                                  id='variationTitle'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                <Input
                                  type='text'
                                  name='variationUID'
                                  value={item.variationUID}
                                  id='variationUID'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                <Input
                                  type='text'
                                  name='variationStock'
                                  value={item.variationStock}
                                  id='variationStock'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                <StyledCheckBox
                                  type='checkbox'
                                  value={item.externalStock}
                                  className='m-1 batch-header'
                                  checked={item.externalStock}
                                  name='externalStock'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                <StyledCheckBox
                                  type='checkbox'
                                  value={item.variationStockable}
                                  className='m-1 batch-header'
                                  checked={item.variationStockable}
                                  name='variationStockable'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                <StyledCheckBox
                                  type='checkbox'
                                  value={item.stockOverride}
                                  className='m-1 batch-header'
                                  checked={item.stockOverride}
                                  name='stockOverride'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                <Input
                                  type='number'
                                  min={1}
                                  name='triggerValue'
                                  value={item.subscriptions && !!item.subscriptions.length && item.subscriptions[0].triggerValue}
                                  id='triggerValue'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                  onKeyDown={e => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()}
                                />
                              </td>
                              <td>
                                <Input
                                  type='text'
                                  disabled={item.subscriptions && (!item.subscriptions.length || !item.subscriptions[0].triggerValue)}
                                  name='subscriberContactValue'
                                  value={item.subscriptions && !!item.subscriptions.length ? item.subscriptions[0].subscriberContactValue : ''}
                                  id='subscriberContactValue'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                  className={item.subscriberContactValueError && 'is-invalid'}
                                />
                              </td>
                              <td>
                                <Input
                                  type='text'
                                  name='variationSize'
                                  value={item.variationSize}
                                  id='variationSize'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                  className={item.subscriberContactValueError && 'is-invalid'}
                                />
                              </td>
                              <td>
                                <Input
                                  type='select'
                                  name='ColorID'
                                  id='ColorID'
                                  value={item.ColorID || ''}
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                >
                                  <option value='' disabled>
                                    Click to Select
                                  </option>
                                  {this.state.colors.map(e => {
                                    return (
                                      <option value={e.ColorID} key={e.ColorID}>
                                        {e.name} {e.hexCode}
                                      </option>
                                    )
                                  })}
                                </Input>
                              </td>
                              <td>
                                <StyledCheckBox
                                  type='checkbox'
                                  value={item.allowPreorder}
                                  className='m-1 batch-header'
                                  checked={item.allowPreorder}
                                  name='allowPreorder'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                <Input
                                  type='number'
                                  name='poolOrder'
                                  value={item.poolOrder}
                                  id='poolOrder'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                <Input
                                  type='number'
                                  name='preorderStock'
                                  value={item.preorderStock}
                                  disabled={!item.allowPreorder}
                                  id='preorderStock'
                                  onChange={t => ProductActions.updateData(t, key, 'variations')}
                                />
                              </td>
                              <td>
                                {item.attachments &&
                                  item.attachments.length &&
                                  item.attachments.map((image, index) => {
                                    return (
                                      <Input
                                        type='select'
                                        key={index}
                                        name='attachments'
                                        value={image.ImageID}
                                        id='attachments'
                                        onChange={t => ProductActions.updateData(t, key, 'variations')}
                                      >
                                        <option value='' disabled>
                                          Click to Select
                                        </option>
                                        {!!this.state.product.attachments.length &&
                                          this.state.product.attachments.map((e, i) => {
                                            return (
                                              <option key={i} value={e.ImageID}>
                                                {e.ImageID}
                                              </option>
                                            )
                                          })}
                                      </Input>
                                    )
                                  })}
                              </td>
                              <td>
                                <Button
                                  className='float-right'
                                  onClick={() => {
                                    ProductActions.removeData(key, 'variations')
                                  }}
                                >
                                  <FontAwesomeIcon icon='minus' />
                                </Button>
                              </td>
                            </tr>
                          )
                        })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Status</label>
              </Col>
              <Col xs='10'>
                <Input type='select' name='productStatus' value={this.state.product.productStatus} onChange={ProductActions.updateProductField}>
                  <option value='' disabled>
                    Click to Select
                  </option>
                  {productStatus.map(e => {
                    return (
                      <option value={e} key={e}>
                        {e}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Schedule</label>
              </Col>
              <Col xs='10'>
                <Row xs='12'>
                  <Col xs='6'>
                    <Input
                      type='date'
                      value={this.state.product.timeStart}
                      name='timeStart'
                      id='timeStart'
                      onChange={ProductActions.updateProductField}
                    />
                  </Col>
                  <Col xs='6'>
                    <Input type='date' value={this.state.product.timeEnd} name='timeEnd' id='timeEnd' onChange={ProductActions.updateProductField} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Product Stock Limit</label>
              </Col>
              <Col xs='10'>
                <InputGroup>
                  <Input
                    value={this.state.product.productStockLimit}
                    name='productStockLimit'
                    id='productStockLimit'
                    onChange={ProductActions.updateProductField}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Stock Available Date</label>
              </Col>
              <Col xs='10'>
                <Input
                  type='date'
                  value={this.state.product.stockAvailableDate}
                  name='stockAvailableDate'
                  id='stockAvailableDate'
                  onChange={ProductActions.updateProductField}
                />
              </Col>
            </Row>
            <Button onClick={ProductActions.saveProduct} disabled={this.state.status === STATUS.SAVING}>
              {this.state.status === STATUS.SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetProduct} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
        <CollapsibleCard startOpen header={<strong>Image Gallery</strong>}>
          <ProductImageUpload />
          <Row className='mt-3'>
            {!!this.state.product.attachments.length &&
              this.state.product.attachments.map((e, i) => {
                return (
                  <div key={i} className='mt-3 px-3'>
                    <StyledImg src={e.imageOriginSource} alt={e.imageName} />
                    <StyledAttachmentDiv>
                      <Row>
                        <Col xs='9'>
                          Image ID:<strong>#{e.ImageID}</strong>
                        </Col>
                        <Col xs='3'>
                          <Button
                            color='danger'
                            onClick={() => {
                              if (confirm('Vil du slette dette image?')) {
                                ProductActions.deleteImage(e.ImageID)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon='trash' />
                          </Button>
                        </Col>
                      </Row>
                      <StyledAttachmentRow style={{ paddingLeft: '15px' }}>
                        <StyledAttachmentOrderCol xs='6'>
                          <StyledAttachmentLabel> PoolOrder:</StyledAttachmentLabel>
                          <StyledAttachmentInput
                            type='number'
                            name='poolOrder'
                            value={e.poolOrder}
                            onChange={t => {
                              ProductActions.updateData(t, i, 'attachments')
                            }}
                          />
                        </StyledAttachmentOrderCol>
                        <StyledAttachmentImageCol xs='6'>
                          <StyledAttachmentLabel>FeatureImage:</StyledAttachmentLabel>
                          <StyledAttachmentCheckbox
                            checked={e.featureImage}
                            type='checkbox'
                            name='featureImage'
                            className='batch-header'
                            value={e.featureImage}
                            onChange={t => {
                              ProductActions.updateData(t, i, 'attachments')
                            }}
                          />
                        </StyledAttachmentImageCol>
                      </StyledAttachmentRow>
                    </StyledAttachmentDiv>
                  </div>
                )
              })}
          </Row>
        </CollapsibleCard>

        <CollapsibleCard startOpen header={<strong>Upload Video</strong>}>
          <VideoUpload onVideoUploaded={ProductActions.videoFileUploaded} />
          <Row className='mt-3'>
            {!!this.state.product.video && (
              <div className='mt-3 px-3'>
                <Row>
                  <Col xs='3'>
                    <video controls className='mt-2' src={this.state.product.video} style={{ width: '100%' }} />
                  </Col>
                </Row>
                <StyledAttachmentDiv>
                  <Row>
                    <Col xs='9'></Col>
                    <Col xs='3'>
                      <Button
                        color='danger'
                        onClick={() => {
                          if (confirm('Vil du slette dette video?')) {
                            ProductActions.deleteVideo()
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                    </Col>
                  </Row>
                </StyledAttachmentDiv>
              </div>
            )}
          </Row>
        </CollapsibleCard>
      </React.Fragment>
    )
  }
}
