import { useState, useEffect } from 'react'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
/* eslint-disable eqeqeq */
import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import 'moment/locale/da'
import { Button, Col, Form, Input, Row } from 'reactstrap'
import FormField from 'Components/formField'
import DatePicker from 'react-datepicker'
import { currencyFormatter } from 'Util/currencyFormatter'

const MerchInvoiceDetails = props => {
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [invoiceElements, setInvoiceElements] = useState([])
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  const createStatusList = ['OPEN']
  const editStatusList = ['OPEN', 'CLOSED']
  const fetchInvoiceDetails = async () => {
    setLoading(true)
    const response = await GET('invoices/adminFetch', { InvoiceID: props.match.params.InvoiceID })
    setLoading(false)
    if (response) {
      setInvoiceDetails(response)
    }
  }

  const fetchInvoiceElements = async () => {
    const response = await GET('invoices/adminElements', { InvoiceID: props.match.params.InvoiceID })
    if (response.length) {
      setInvoiceElements(response)
    }
  }

  const fetchByDepartment = async () => {
    const response = await GET('invoices/merchandiseDetails', { InvoiceID: props.match.params.InvoiceID })
    if (response.length) {
      setDepartments(response)
    }
  }

  const saveInvoice = async () => {
    setLoading(true)
    await POST('invoices/replace', invoiceDetails)
    setLoading(false)
  }

  const updateDateField = (m, field) => {
    let value = ''
    if (field === 'timeEnd') {
      value = m.endOf('day').toString()
    } else if (field === 'timeStart') {
      value = m.startOf('day').toString()
    }
    setInvoiceDetails({ ...invoiceDetails, [field]: value })
  }

  useEffect(() => {
    fetchInvoiceDetails()
    fetchInvoiceElements()
    fetchByDepartment()
  }, [])

  return loading ? (
    <FontAwesomeIcon icon='spinner' spin />
  ) : (
    <React.Fragment>
      <CollapsibleCard startOpen={false} header={<strong>Detaljer for faktura</strong>}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField
            data={invoiceDetails}
            field={'invoiceTitle'}
            name='Titel'
            onChange={e => setInvoiceDetails({ ...invoiceDetails, invoiceTitle: e.target.value })}
            required
          />
          <FormField field={'invoiceStatus'} name='Status'>
            <Input
              type='select'
              name='invoiceStatus'
              value={invoiceDetails.invoiceStatus}
              onChange={e => setInvoiceDetails({ ...invoiceDetails, invoiceStatus: e.target.value })}
            >
              {props.create &&
                createStatusList.map(e => {
                  return (
                    <option value={e} key={e}>
                      {e}
                    </option>
                  )
                })}
              {!props.create &&
                editStatusList.map(e => {
                  return (
                    <option value={e} key={e}>
                      {e}
                    </option>
                  )
                })}
            </Input>
          </FormField>
          <FormField field={'timeStart'} name='Start dato'>
            <DatePicker
              selected={moment(invoiceDetails.timeStart)}
              onChange={e => {
                updateDateField(e, 'timeStart')
              }}
            />
          </FormField>
          <FormField field={'timeEnd'} name='Slut dato'>
            <DatePicker
              selected={moment(invoiceDetails.timeEnd)}
              onChange={e => {
                updateDateField(e, 'timeEnd')
              }}
            />
          </FormField>
          <Button onClick={saveInvoice} disabled={loading}>
            {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={fetchInvoiceDetails} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
      {!!departments.length && (
        <CollapsibleCard header={<strong>Departments</strong>} startOpen>
          <Row style={{ marginBottom: 8 }}>
            <Col>
              <strong>Department</strong>
            </Col>
            <Col>
              <strong>Total Amount</strong>
            </Col>
          </Row>
          {departments.map(item => {
            return (
              <Row key={item.department}>
                <Col>{item.department}</Col>
                <Col>{item.total ? currencyFormatter.format(item.total) : '0,00 kr.'}</Col>
              </Row>
            )
          })}
        </CollapsibleCard>
      )}
      <CollapsibleCard header={<strong>Linier</strong>} startOpen>
        <DynamicTable
          responsive
          size='sm'
          data={invoiceElements}
          headers={{
            OrderlineID: '#',
            ConfirmationID: 'Confirmation ID',
            OrderID: 'Ordre ID',
            orderType: 'Order Type',
            UserID: 'Bruger ID',
            userName: 'Bruger',
            userUID: 'Unikt ID',
            userCompany: 'Virksomhed',
            orderlineTitle: 'Vare',
            orderAmountPoints: 'Point',
            orderPointRatio: 'Ratio',
            orderlineProductPrice: 'Basis',
            orderCurrency: 'Valuta',
            timeInsert: 'Oprettet',
            timeShipped: 'Afsendt',
            vaultSlug: 'Vault Slug'
          }}
          options={{
            fieldFunctions: {
              orderType: e => {
                return e.orderCustomOrder ? 'Custom' : 'Normal'
              },
              timeInsert: e => {
                return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY HH:mm') : ''
              },
              timeShipped: e => {
                return e.timeShipped ? moment(e.timeShipped).format('DD-MM-YYYY HH:mm') : ''
              }
            },
            fieldStringFunctions: {
              timeInsert: e => {
                return moment(e.timeInsert).format('x')
              },
              timeShipped: e => {
                return moment(e.timeShipped).format('x')
              }
            }
          }}
        />
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default MerchInvoiceDetails
